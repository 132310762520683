var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-sm-5",attrs:{"id":"payment-failed"}},[_c('div',{staticClass:"d-flex flex-wrap justify-content-center align-items-center"},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center mb-2"},[_c('h3',{staticClass:"font-weight-bolder mb-1"},[_vm._v(" Transaksi Gagal ")]),_vm._m(0),_c('div',{staticClass:"d-flex flex-column mt-75"},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary","to":{
              name:'payment-order-detail',
              params: {
                paymentId: _vm.$route.params.paymentId,
                item: _vm.$route.params.item,
                itemId: _vm.$route.params.itemId
              }
            }}},[_vm._v(" Kembali ke Pembayaran ")]),_c('b-button',{staticClass:"w-100",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.$refs['join-community-modal'].show()}}},[_vm._v(" Gabung Komunitas ")])],1)]),_c('b-img',{attrs:{"fluid":"","src":require('@/assets/images/pages/payment-failed.svg'),"alt":"payment-failed-image"}})],1),_c('b-modal',{ref:"join-community-modal",attrs:{"modal-class":"payment-modal","hide-footer":"","centered":"","header-bg-variant":"white"}},[_c('join-community')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column text-center mb-2"},[_c('span',{staticClass:"font-weight-bolder text-black"},[_vm._v(" Transaksi Anda gagal karena ada kesalahan teknis. ")]),_c('span',{staticClass:"font-weight-bolder text-black"},[_vm._v(" Silakan coba beberapa saat lagi. ")])])
}]

export { render, staticRenderFns }